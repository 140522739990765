// main font for text
@mixin fontMain {
	font-family: 'Open Sans', sans-serif; }

// main font size
$fontSizeBase: 14;

// text colors and backgrounds
$main: #1b1d1e; // main color
$mainDark: #373737; // dark heading color (typography page)
$muted: #565656; // paragraph text color (typography page)
$mutedLight: #b3b3b3; // accordion headings (typography page)
$blue: #006eb9; // nav link hover background, blue buttons (typography page)
$violet: #b04495; // accordion plus/minus, violet buttons (typography page)


$orange: #f3ac1e;
$red: #df4949;
$blueDark: #34495e;

// social colors
// &.facebook
// 	color: #3b5998
// &.twitter
// 	color: #55acee
// &.google-plus
// 	color: #dc4e41
// &.feed
// 	color: #ecb216
