@import "mixins";
@import "variables";

// // offset for menu dropdown levels





/*DESKTOP*/
@media screen and (min-width: 992px) {

	/*custom cloumns*/
	.col-lg-1-5 {
		float: left;
		position: relative;
		min-height: 1px; }

	.col-lg-1-5 {
		width: 20%; } }



/*TABLET AND DESKTOP*/
@media screen and (min-width: 768px) {

	/*auto width of first level menu links for full width menu*/
	.mash-menu {
		.mash-list-items {
			display: table !important;
			> li {
				float: none;
				display: table-cell !important; } } }

	.same-height-parent {
		float: none !important;
		display: table;
		width: 100%; }

	.same-height {
		float: none !important;
		display: table-cell;
		vertical-align: top; }

	/*custom cloumns*/
	.col-md-1-5 {
		float: left;
		position: relative;
		min-height: 1px; }

	.col-md-1-5 {
		width: 20%; } }






/*SMALL DESKTOP AND LESS BEGIN*/
/*-----------------------------------------------------*/

@media screen and (max-width: 1199px) {

	.navbar-nav {
		.nav-item {
			.nav-link {
				@include rem(16); } } }

	.mash-menu {
		.mash-list-items {
			> li {
				> a {
					i.fa,
					i.fa.fa-indicator {
						@include rem(12); } } } } }





	/*HOMEPAGE START*/
	/*--------------------------------------------------------------------------*/

	.life-side-container {
		margin-right: -110px;
		margin-left: -110px; }

	.life-post-text-left {
		padding-left: 110px;
		padding-right: 10%; }

	.life-post-text-right {
		padding-right: 110px;
		padding-left: 10%; }

	.testimonials {
		padding-top: 80px;
		padding-bottom: 30px; }

	.slider-text {
		width: 40%; }

	/*--------------------------------------------------------------------------*/
	/*HOMEPAGE END*/






	/*PROGRAMS PAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.program-box-text {
		padding-bottom: 75px; }


	/*--------------------------------------------------------------------------*/
	/*PROGRAMS PAGE END*/ }










/*-----------------------------------------------------*/
/*SMALL DESKTOP END*/




/*TABLET ONLY BEGIN*/
/*-----------------------------------------------------*/

@media screen and (min-width: 768px) and (max-width: 991px) {

	.mash-menu {
		.mash-list-items {
			> li {
				vertical-align: middle;
				> a {
					i.fa,
					i.fa.fa-indicator {
 } } } } } }						// display: none


/*-----------------------------------------------------*/
/*TABLET ONLY END*/




/*TABLET AND PHONE BEGIN*/
/*-----------------------------------------------------*/

@media screen and (max-width: 991px) {

	.side-padding {
		padding-right: 15px;
		padding-left: 15px; }

	.bottom-offset {
		margin-bottom: 20px; }

	.nav-main {
		padding-top: 10px;
		margin-right: -15px;
		margin-left: -15px; }

	.navbar-nav {
		.nav-item {
			.nav-link {
				@include rem(15);
				padding: 10px 10px; }
			+ .nav-item {
				.nav-link:before {
					width: 1px;
					height: 20px;
					margin-top: -10px; } } }
		.vertical-tabs {
			a {
				// white-space: nowrap
				// text-overflow: ellipsis
				// overflow: hidden
				.fa {
					display: none; } } } }

	.logo {
 }		// width: 130px

	.logo-text {
		padding-left: 30px;
		margin-right: -80px;
		margin-left: 0;
		@include rem(12); }

	.header-search-form {
		box-shadow: 0 0 3px 0px rgba(#000, 0.3); }







	/*HOMEPAGE START*/
	/*--------------------------------------------------------------------------*/

	.slider-main {
		.owl-item {
			.slider-main-img {
				width: 140%;
				margin-left: -20%; } } }

	.slider-text {
		width: 50%; }

	.programs-heading {
		margin-bottom: 10px; }

	.program-item {
		padding-bottom: 15px; }

	.program-item-top-text {
		margin-bottom: 12px; }

	.program-item-list {
		padding-top: 12px; }

	.events {
		padding-bottom: 40px; }

	.news {
		padding-bottom: 15px; }

	.life {
		min-height: 200px; }

	.life-side-container {
		margin-right: -110px;
		margin-left: -110px; }

	.life-post-text-left {
		padding-left: 140px;
		padding-right: 60px; }

	.life-post-text-right {
		padding-right: 140px;
		padding-left: 60px; }

	.testimonials {
		padding-top: 50px;
		padding-bottom: 1px; }

	.gallery-text {
		padding-top: 10px;
		padding-bottom: 10px; }

	.subscibe-form .form-control {
		width: 240px; }

	.subscribe {
		padding-top: 20px;
		padding-bottom: 15px; }

	.footer {
		padding-top: 22px;
		padding-bottom: 8px;
		hr {
			margin-bottom: 14px; } }

	.footer-nav {
		padding-bottom: 0; }




	/*--------------------------------------------------------------------------*/
	/*HOMEPAGE END*/


	/*ARTICLE PAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.blockquote {
		padding: 27px 17px;
		margin: 25px 0 17px 0; }

	.articles-nav {
		padding: 10px 10px 5px 10px; }

	.sidebar-events {
		padding: 15px 0px 25px 0px;
		.section-heading {
			margin-bottom: 15px; }
		.event-text {
			margin-bottom: 10px; } }

	.testimonials-areas-item-left {
		padding-right: 10px; }

	.gallery-slider {
		padding-top: 15px; }

	/*ARTICLE PAGE END*/
	/*--------------------------------------------------------------------------*/






	/*PROGRAMS PAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.program-box-text,
	.program-box-img-container {
		float: left; }

	.program-box-text,
	.programs-cycle-box {
		padding: 15px 15px 35px 15px; }

	.program-box-arrow,
	.programs-cycle-box-arrow {
		bottom: 10px;
		right: 15px;
		width: 32px;
		height: auto; }


	/*--------------------------------------------------------------------------*/
	/*PROGRAMS PAGE END*/ }



/*-----------------------------------------------------*/
/*TABLET AND PHONE END*/









/*PHONE BEGIN*/
/*-----------------------------------------------------*/

@media screen and (max-width: 767px) {

	/*TYPOGRAPHY BEGIN*/
	/*-----------------------------------------------------*/

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6,
	.mash-menu h1, .mash-menu h2, .mash-menu h3, .mash-menu h4, .mash-menu h5, .mash-menu h6 {
		line-height: 1.3; }

	h1,
	.mash-menu h1 {
		@include rem(22) /*22px*/; }

	.bottom-offset-2x {
		margin-bottom: 40px; }

	.heading-underline-icon {
		margin-bottom: 15px; }




	/*-----------------------------------------------------*/
	/*TYPOGRAPHY END*/


	.header-mobile {
		padding: 10px 0; }

	.logo-mobile {}

	.navbar-toggler {
		position: absolute;
		top: 7px;
		right: 15px;
		width: 30px;
		padding: 7px 10px;
		box-sizing: content-box;
		// border: $blue 1px solid
		background: rgba(#fff, 0.6);
		border-radius: 0;
		@include hf {
			outline: none; }
		div {
			height: 3px;
			margin: 5px 0;
			background: $blue; } }

	.header,
	.header-right {
		padding-top: 0; }

	.header-right {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;
		padding: 10px 15px;
		background: #fff; }

	.mobile-menu-bottom-col {
		position: static; }

	.header-search {
		display: block;
		margin-bottom: 10px;
		.header-search-input {
			width: 100%; } }

	.header-search-toggler {
		display: none; }

	.header-search-form {
		position: static;
		display: block !important;
		box-shadow: none; }

	.header-search-input {
 }		// padding-right: 10px

	.header-search-btn {}

	.social-list {
		display: block;
		li {
			float: none;
			@include in; } }

	.nav-main {}

	.mash-menu {
		.mash-list-items {
			display: block;
			padding-top: 0; }
		[class*='col-'] {
			margin-bottom: 0; }
		.drop-down-large,
		.drop-down-medium,
		.drop-down {
			position: static;
			> .vertical-tabs-container {
				top: 0;
				padding-right: 0; } }
		.vertical-tabs {
 }	    	// display: block
		.vertical-tabs-content-container {
			margin-right: -15px;
			margin-left: -15px;
			font-size: 1rem;
			.vertical-tabs-content {
				float: none;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-right: 15px;
				padding-left: 15px;
				.vertical-tabs {
					display: block; } }
			a.active + .vertical-tabs-content {
				position: relative;
				z-index: 10000; }
			> a {
				float: inherit;
				text-align: inherit;
				padding: 14px 7px 11px 10px;
				margin: 0;
				font-weight: inherit;
				color: inherit;
				&:hover,
				&.active {
					background: inherit;
					color: inherit; }
				i.fa {
					float: none;
					padding-left: 6px; } } }
		.nav-item {
			> ul {
				> li {
					> a {
 } } } }						// padding-left: 30px

		.drop-down {
			.drop-down {
				> li,
				> li:last-child,
				> li:first-child {
					> a {
						padding-left: 40px; }
					> ul {
						> li {
							a {
								padding-left: 65px; } } } } } } }

	.navbar-nav {
		.nav-item {
			.nav-link {
				border-bottom: #ccc 1px dotted;
				text-align: left; } } }

	/*HOMEPAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.slider-main {
		.owl-item {
			.slider-main-img {
				width: 300%;
				margin-left: -100%; } } }

	.slider-text {
		width: 70%;
		padding-bottom: 55px;
		p {
			margin-bottom: 0;
			+ p {
				margin-top: 14px; } } }

	.slider-main-nav {
		bottom: 15px; }

	.event-text-container,
	.event-btn-container {
		width: 100%; }

	.event-btn-container {
		padding-right: 0; }

	.life-side-container {
		margin-right: 0;
		margin-left: 0; }

	.life-post-img-container,
	.life-post-img {
		@include transform(none); }

	.life-post-img-container {
		.life-post-img {
			width: 140%;
			margin-right: 0;
			margin-left: 0; } }

	.life-post-text {
		right: 0;
		left: 0;
		padding: 0 30px; }

	.testimonials {
		padding-top: 30px; }

	.testimonials-areas-item-left {
		padding-right: 15px; }

	.testimonials-slider {
		.owl-controls {
			margin-top: 15px; } }

	.testimonial-author {
		padding-left: 0;
		padding-right: 0; }

	.testimonials-more {
		bottom: 5px; }

	.col-2-5,
	.col-1-5 {
		float: none;
		width: 100%; }

	.subscibe-text-overflow {
		p {
			margin-bottom: 0; } }

	.subscibe-form {
		margin-top: 0;
		.form-control {
			width: 100%;
			margin-bottom: 10px; }
		.btn {
			width: 100%; } }

	.footer-nav-list {
		li {
			float: left;
			display: block;
			width: 50%;
			margin-bottom: 5px;
			+ li:before {
				display: none; } } }

	/*--------------------------------------------------------------------------*/
	/*HOMEPAGE END*/






	/*ARTICLE PAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.main-padding {
		padding-top: 15px; }

	.news-preview-icon-and-date {
		padding-right: 10px; }

	.article-social {
		margin: 20px 0 13px 0; }

	.gallery-slider {
		margin-bottom: 5px; }

	.articles-nav {
		padding: 5px 5px 1px 5px; }

	/*--------------------------------------------------------------------------*/
	/*ARTICLE PAGE END*/






	/*PROGRAMS PAGE BEGIN*/
	/*--------------------------------------------------------------------------*/

	.program-box-text,
	.program-box-img-container {
		float: none;
		width: 100%; }

	.program-box-text {
		padding-bottom: 40px; }


	/*--------------------------------------------------------------------------*/
	/*PROGRAMS PAGE END*/ }









/*-----------------------------------------------------*/
/*PHONE END*/
