// MIXINS START
// ------------------------------------------------

@mixin middle {
	display: inline-block;
	vertical-align: middle; }

// selected text properties
@mixin selection($color) {
	::moz-selection {
	    background: $color; }

	::selection {
	    background: $color; } }

// hover and focus
@mixin hf {
	&:hover, &:focus {
		@content; } }

@mixin clearfix {
	&:before,
	&:after {
		@include ct;
		clear: both; } }

@mixin zIndexClasses($zNumber) { // zNumber - number of classes

	@for $i from 1 through $zNumber {
		.z#{$i} {
			z-index: $i; } } }

@mixin opacity($opacity) {
	opacity: $opacity; }

@mixin in {
	display: inline-block;
	vertical-align: top; }

@mixin rem($fontSize) {
	font-size: ($fontSize/$fontSizeBase) + rem; }

@mixin transition($transition) {
	-webkit-transition: $transition;
	transition: $transition; }

@mixin box {
	// -webkit-box-sizing: border-box
	box-sizing: border-box; }

@mixin ct {
	content: '';
	display: block; }

@mixin linear($color1, $color2) {
	background: $color2;
	background: -webkit-linear-gradient($color1, $color2);
	background: linear-gradient($color1, $color2); }

@mixin linearLeft($color1, $color2) {
	background: $color2;
	background: -webkit-linear-gradient(left, $color1, $color2);
	background: linear-gradient(to right, $color1, $color2); }

@mixin radial($color1, $color2) {
	background-image: $color2;
	background-image: -webkit-radial-gradient($color1, $color2);
	background-image: radial-gradient($color1, $color2); }

@mixin transform($value) {
	-ms-transform: $value;
	-webkit-transform: $value;
	transform: $value; }

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; }

	@keyframes #{$name} {
		@content; } }

@mixin animation($value) {
	-webkit-animation: $value;
	animation: $value; }

@mixin noSelect {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

@mixin transitionMain {
	@include transition(all 0.2s ease-out); }

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; }



// $btn-name-outline: (name, background)
// $btn-primary: ('primary', $blue)
// $btn-secondary: ('secondary', #eee)
// $btn-success: ('success', $success)
// $btn-info: ('info', $info)
// $btn-warning: ('warning', $warning)
// $btn-danger: ('danger', $danger)

// $btns-regular: ($btn-primary, $btn-secondary, $btn-info, $btn-warning, $btn-danger)

// @mixin btns-regular($btns)
// 	@each $btn in $btns
// 		$name: nth($btn, 1)
// 		$background: nth($btn, 2)

// 		.btn-#{$name}
// 			background-color: $background
// 			border-color: $background
// 			&:hover, &:focus
// 				background-color: darken($background, 6%)
// 				border-color: darken($background, 6%)
// 			&:active
// 				border-color: darken($background, 10%)
// 				background: darken($background, 10%)

// @include btns-regular($btns-regular)


// ---------------------------------------------------------------------


// $btn-name-outline: (name, border-color, color, hover-background, hover-color)
// $btn-primary-outline: ('primary', $blue, #000, $blue, #fff)
// $btn-secondary-outline: ('secondary', #fff, #fff, #fff, $blue)
// $btn-success-outline: ('success', $success, $main, $success, #fff)
// $btn-info-outline: ('info', $info, $main, $info, #fff)
// $btn-warning-outline: ('warning', $warning, $main, $warning, #fff)
// $btn-warning-outline: ('warning', $warning, $main, transparent, $warning)
// $btn-danger-outline: ('danger', $danger, $main, $danger, #fff)

// all buttons
// $btns-outline: ($btn-primary-outline, $btn-secondary-outline, $btn-success-outline, $btn-info-outline, $btn-warning-outline, $btn-danger-outline)

// @mixin btns-outline($btns)
// 	@each $btn in $btns
// 		$name: nth($btn, 1)
// 		$border-color: nth($btn, 2)
// 		$color: nth($btn, 3)
// 		$hover-background: nth($btn, 4)
// 		$hover-color: nth($btn, 5)

// 		.btn-#{$name}-outline
// 			border-color: $border-color
// 			color: $color
// 			&:hover, &:focus
// 				background-color: $hover-background
// 				border-color: $border-color
// 				color: $hover-color
// 			&:active
// 				border-color: darken($hover-background, 6%)
// 				background-color: darken($hover-background, 6%)

// @include btns-outline($btns-outline)

// ---------------------------------------------------------------------


// ------------------------------------------------
// MIXINS END
